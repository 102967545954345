
<template>
  <div class="wrapper">
    <level-nav @add="handleAddItem" @search="handleSearch"></level-nav>
    <list :list="list" @edit="handleEditItem"></list>
    <edit-level
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-level>
  </div>
</template>

<script>
import LevelNav from "./components/Nav";
import List from "./components/List";
import EditLevel from "./components/EditLevel";
export default {
  name: "AgentLevel",
  components: {
    LevelNav,
    List,
    EditLevel,
  },
  data() {
    return {
      show: false,
      type: "add",
      item: {},
      list: [],
      lock: true,
    };
  },
  watch: {},
  created() {
    this.getAgentLevelList();
  },
  methods: {
    //获取代理等级列表
    async getAgentLevelList() {
      const res = await this.$store.dispatch("getAgentLevelList");
      this.list = res.items;
      this.allList = this.list;
    },
    //搜索
    handleSearch(val) {
      if (val === "") {
        this.list = this.allList;
        return;
      }
      this.list = this.allList.reduce((prev, cur) => {
        if (cur.name.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //提交成功
    handleSucc() {
      this.getAgentLevelList();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  width: 100%;
}
</style>