<template>
  <table-container :list="list">
    <el-table-column prop="id" label="ID" width="50"> </el-table-column>
    <el-table-column prop="name" label="级别名称" width="180">
    </el-table-column>
    <el-table-column prop="valid" label="当前代理数"> </el-table-column>
    <el-table-column prop="maxUsers" label="最大代理数"> </el-table-column>
    <el-table-column prop="price_ratio" label="价格比例"> </el-table-column>
    <el-table-column prop="created_at" label="创建日期"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)">编辑</el-button
        ><delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delAgentType", {
        level_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>