<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
    >
      <el-form-item label="级别名:">
        <el-input v-model.trim="name"></el-input>
      </el-form-item>
      <el-form-item label="最大代理数:">
        <el-input v-model.trim="maxUsers"></el-input>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model.trim="sort"></el-input>
      </el-form-item>
      <el-form-item label="价格比例:">
        <el-input
          v-model.trim="price_ratio"
          placeholder="0-1之间的数"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
export default {
  name: "EditLevel",
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增代理级别",
      labelPosition: "right",
      id: "",
      name: "",
      maxUsers: "",
      sort: 1,
      price_ratio: "",
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增代理级别";
        this.name = "";
        this.sort = 1;
        this.maxUsers = null;
        this.price_ratio = null;
      } else {
        this.title = "编辑代理级别";
        this.id = this.item.id;
        this.name = this.item.name;
        this.sort = this.item.sort;
        this.maxUsers = this.item.maxUsers;
        this.price_ratio = this.item.price_ratio;
      }
    },
  },
  methods: {
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = {
        name: this.name,
        maxUsers: this.maxUsers,
        sort: this.sort,
        price_ratio: this.price_ratio,
      };
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.id = this.id;
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addAgentLevel", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editAgentLevel", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>